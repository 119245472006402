
import { fetchFromUrlGETAsync, fetchFromUrlPOSTAsync } from '../utils/UrlHelper';

const URL_EXPORT_CHAT = "chat/export";
const URL_GET_EXPORTED_CHAT = "chat/list_export";



const fs = {
    exportChat: async (params) => {
        const urlParams = [
        ];
        if (params) {
            for (let index = 0; index < Object.keys(params).length; index++) {
                const element = Object.keys(params)[index];
                let obj = { key: element, value: params[element] }
                urlParams.push(obj)
            }
        }
        const result = await fetchFromUrlPOSTAsync(URL_EXPORT_CHAT, urlParams);
        return result;
    },
    getExportedChatRequest: async (params) => {
        const urlParams = [
        ];
        if (params) {
            for (let index = 0; index < Object.keys(params).length; index++) {
                const element = Object.keys(params)[index];
                let obj = { key: element, value: params[element] }
                urlParams.push(obj)
            }
        }
        const result = await fetchFromUrlGETAsync(URL_GET_EXPORTED_CHAT, urlParams);
        return result;
    },
    


};

//this is local function on Api 
const loc = {
   

};



const ChatActions = Object.assign(fs, loc);

export default ChatActions;