import React, { createContext, useEffect, useState } from "react";
import { QueryClient, QueryClientProvider, QueryKey } from "react-query";
import { ConfigProvider } from "antd";
import { isBrowser, isMobile } from 'react-device-detect';
import "./App.scss";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate,
  useNavigate,
} from "react-router-dom";
import Login from "./pages/auth/Login";
import Spaces from "./pages/spaces";
import Nav from "./components/layout/Nav";
import Profile from "./pages/users/Profile";
import MessagesPage from "./pages/messages";
import MobileAppPage from "./pages/mobileAppPage/MobileAppPage";

import UsersMangment from "./pages/users";
import Call from "./pages/call";
import ContactsPage from "./pages/contacts";
import IncommingCall from "./components/call/IncommingCall";
import { RejectCall } from "./services/calls";
import { FetchGroups } from "./services/groups";
import { FetchMessages, FetchUsersMessages, MakeSeen } from "./services/chats";
import { createWebStoragePersistor } from "react-query/createWebStoragePersistor-experimental";
import { persistQueryClient } from "react-query/persistQueryClient-experimental";

import { database, getFCMTokenAndDeviceId } from "./firebaseConfig";
import { ref, set, get, child } from "firebase/database";
import moment from "moment";
import {  showOldLog } from "./ConfigVar";
import { useAccessToken } from "./redux/ApiStorage";
import PusherConnection from "./pusher/PusherConnection";
import Splash from "./pages/splash/Splash";

export const MessageContext = createContext<any>(null);
export const RoomContext = createContext<any>(null);
export const LastContext = createContext<any>(null);
export const NotificationContext = createContext<any>(null);
export const CallContext = createContext<any>([]);
export const CallContextEnd = createContext<any>(false);

function App() {

  localStorage.debug = 'socket.io-client:socket';

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        cacheTime: 1000 * 60 * 60 * 24, // 24 hours
      },
    },
  });

  const localStoragePersistor = createWebStoragePersistor({
    storage: window.localStorage,
  });

  persistQueryClient({
    queryClient,
    persistor: localStoragePersistor,
    maxAge: 1000 * 60 * 60 * 24,
    hydrateOptions: {},
    dehydrateOptions: {
      shouldDehydrateQuery: (query) => {
        const queryIsReadyForPersistance = query.state.status === "success";
        if (queryIsReadyForPersistance) {
          const { queryKey } = query;
          const excludeFromPersisting = queryKey.includes("Messages");
          return !excludeFromPersisting;
        }
        return queryIsReadyForPersistance;
      },
    },
  });
  const [UserLoc, setUserLoc] = useState<any>({});
  const [NewMessage, SetNewMessage] = useState<any>();
  const [Room, SetRoom] = useState<any>(0);
  const [Last, setLast] = useState<any>({});
  const [NotificationRoom, SetNotificationRoom] = useState<any>(0);
  const [incomingCall, setIncomingCall] = useState(false); // State to track incoming call
  const [IncomingCallInfo, setIncomingCallInfo] = useState<any>({});
  const [FinsishCallInfo, setFinsishCallInfo] = useState<any>({});
  const [dataCall, setDataCall] = useState<any>([]);
  const [dataCallEnd, setDataCallEnd] = useState<any>(false);
  const [allMessagesId, setAllMessages] = useState<any>([]);
  const [audio] = useState(new Audio("/call.mp3"));



  useEffect(() => {
    if (UserLoc?.id) {
      if (showOldLog) {
        console.log("momentmoment", moment.now())
      }
      set(ref(database, UserLoc?.id.toString()), {
        last_seen: moment.now(),
        presence: true,
      });
      window.addEventListener("beforeunload", function (e) {
        set(ref(database, UserLoc?.id.toString()), {
          last_seen: moment.now(),
          presence: false,
        });
      });
    }
  }, [UserLoc]);
  useEffect(() => {
    if (Object.keys(UserLoc).length > 0) {
      FetchGroups(1, 0, "").then((res) => {
        res?.json.map((gro: any) => {
          if (gro?.staff_chat?.id) {
            FetchMessages(gro?.staff_chat?.id, 1, 0, "").then((res1) => {
              res1?.json.map((msg: any) => {
                let newIds = allMessagesId;
                newIds.push(msg?.id);
                setAllMessages([...newIds]);
              });
            });
          }
          FetchMessages(gro.id, 1, 0, "").then((res1) => {
            res1?.json.map((msg: any) => {
              let newIds = allMessagesId;
              newIds.push(msg?.id);
              setAllMessages([...newIds]);
            });
          });
        });
      });

      FetchUsersMessages(1, "").then((res) => {
        res?.json.map((gro: any) => {
          FetchMessages(gro.id, 1, 0, "").then((res1) => {
            res1?.json.map((msg: any) => {
              let newIds = allMessagesId;
              newIds.push(msg?.id);
              setAllMessages([...newIds]);
            });
          });
        });
      });
    }
  }, []);
  useEffect(() => {
    if (showOldLog) {
      console.log("allMessagesId", allMessagesId);
      console.log("allMessagesId", allMessagesId.length);
    }

  }, [allMessagesId]);
  useEffect(() => {
    if (incomingCall) {
      audio.play();
      document.title = "CALL 🔴";
    } else {
      document.title = "H1Communicator";
      audio.pause();
      audio.currentTime = 0; // Reset audio to the beginning
    }
  }, [incomingCall]);

  useEffect(() => {
    if (showOldLog) {
      console.log("Room Room id", Room);
    }
  }, [Room]);

  const handleChangeIncomingCall = (incomingCallStatus: boolean) => {
    setIncomingCall(incomingCallStatus);
  };

  useEffect(() => {
    const t = JSON.parse(window.localStorage.getItem("user") as string);
    setUserLoc(t);
    for (let i = 0; i < 30; i++) {
      // RejectCall(7920 + i);
    }
  }, [window.localStorage.getItem("user")]);


  useEffect(() => {
    getFCMTokenAndDeviceId()
  }, [])
  const [accessToken] = useAccessToken()

  const { pathname } = useLocation()

  return (
    <QueryClientProvider client={queryClient}>
      {accessToken && UserLoc &&
        <PusherConnection
          key={UserLoc?.id}
          UserLocID={UserLoc?.id}
          SetNewMessage={SetNewMessage}
          setIncomingCall={setIncomingCall}
          setIncomingCallInfo={setIncomingCallInfo}
          setDataCall={setDataCall}
          setFinsishCallInfo={setFinsishCallInfo}
          allMessagesId={allMessagesId}
          SetNotificationRoom={SetNotificationRoom}
          setAllMessages={setAllMessages}
        />

      }
      <ConfigProvider
        theme={{
          token: {
            // Seed Token
            colorPrimary: "#9b1724",
            // Alias Token
            colorBgContainer: "#f1f2f4",
          },
        }}
      >
        <CallContext.Provider value={dataCall}>
          <MessageContext.Provider value={[NewMessage, SetNewMessage]}>
            <NotificationContext.Provider
              value={[NotificationRoom, SetNotificationRoom]}
            >
              <RoomContext.Provider value={[Room, SetRoom]}>
                <LastContext.Provider value={[Last, setLast]}>
                  <div
                    className="App"
                    style={{
                      height: "100vh",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {(pathname !== "/login" && pathname !== "/mobilePage" && pathname!== "/loading") && <Nav />}{" "}
                    {/* Render Nav only if token exists */}
                    <div
                      style={{
                        flexGrow: 1,
                        display: "flex",
                        flexDirection: "column",
                        minHeight: 0,
                      }}
                    >
                      <Routes>
                        {isBrowser ? <>

                          <Route path="/users" Component={UsersMangment} />
                          <Route path="/login" Component={Login} />
                          <Route path="/spaces" Component={Spaces} />
                          <Route path="/contacts" Component={ContactsPage} />
                          <Route path="/messages" Component={MessagesPage} />
                          <Route path="/users/profile" Component={Profile} />
                          <Route path="/calls" Component={Call} />
                          <Route path="/loading" Component={Splash} />


                          
                          <Route path="*" element={ <Navigate to="/loading" />} />
                        </> :
                          <>
                            <Route path="/mobilePage" Component={MobileAppPage} />
                            <Route path="*" element={<Navigate to="/mobilePage" />} />
                          </>
                        }

                      </Routes>
                      {incomingCall && (
                        <IncommingCall
                          IncomingCallInfo={IncomingCallInfo}
                          FinsishCallInfo={FinsishCallInfo}
                          handleChangeIncomingCall={handleChangeIncomingCall}
                          audio={audio}
                        />
                      )}{" "}
                      {/* Render IncommingCall component if there's an incoming call */}
                    </div>
                  </div>
                </LastContext.Provider>
              </RoomContext.Provider>
            </NotificationContext.Provider>
          </MessageContext.Provider>
        </CallContext.Provider>
      </ConfigProvider>
    </QueryClientProvider>
  );
}

export default App;
