import React, { useEffect, useRef } from 'react'
import Pusher from "pusher-js";
import { useAccessToken, useDeviceId } from '../redux/ApiStorage';
import { REACT_APP_API_BASE_URL, REACT_APP_PUSHER_API_KEY, showOldLog } from '../ConfigVar';
import { notification } from "antd";
import { useNavigate } from 'react-router-dom';
import { connectSocket, disconnectSocket } from '../utils/UtilSocket';
import { debounce } from 'lodash';

const pako = require("pako");
const atob = require("atob");

// Create a debounced trigger function
const debouncedTrigger = debounce((channel, eventName, data) => {
    for (let index = 0; index < data.length; index++) {
        const element = data[index];
        channel.trigger(eventName, element);

    }
}, 200); // Adjust delay as needed


// Add this helper function at the top of the file
const safeJsonParse = (strData) => {
  try {
    return JSON.parse(strData);
  } catch (e) {
    console.warn('Invalid JSON string:', e);
    return null;
  }
}

// Add this helper function at the top of the file
const safeAtob = (b64Data) => {
    try {
        // Make sure padding is correct
        const padding = '='.repeat((4 - (b64Data.length % 4)) % 4);
        const base64 = (b64Data + padding).replace(/\-/g, '+').replace(/_/g, '/');
        return atob(base64);
    } catch (e) {
        console.warn('Invalid base64 string:', e);
        return '';
    }
}


const PusherConnection = (props) => {

    const {
        SetNewMessage,
        UserLocID,
        setIncomingCall,
        setIncomingCallInfo,
        setDataCall,
        setFinsishCallInfo,
        allMessagesId,
        SetNotificationRoom,
        setAllMessages,
    } = props


    const [accessToken] = useAccessToken()
    const [deviceId] = useDeviceId()

    const navigate = useNavigate();
    const pusherRef = useRef(null);
    const previousChannelRef = useRef(null);
    let socket = null

    const connectSocketFun = async () => {
        socket = await connectSocket();
    }

    useEffect(() => {

        if (UserLocID && accessToken) {

            // Unsubscribe from previous channel if exists
            if (previousChannelRef.current) {
                pusherRef.current?.unsubscribe(previousChannelRef.current);
            }

            // Initialize Pusher if not already initialized
            if (!pusherRef.current) {

                pusherRef.current = new Pusher(REACT_APP_PUSHER_API_KEY, {
                    cluster: "ap1",
                    channelAuthorization: {
                        endpoint: `${REACT_APP_API_BASE_URL}broadcasting/auth`,
                        transport: "ajax",
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    },
                });
                connectSocketFun();
            }

            // Store new channel name
            const channelName = `private-channel-user-${UserLocID}`;
            previousChannelRef.current = channelName;

            // Subscribe to new channel
            const channel = pusherRef.current.subscribe(channelName);
            // Bind events
            channel.bind("incoming_call", function (data) {
                var b64Data = data.payload;
                var strData = atob(b64Data);
                var charData = strData.split("").map(function (x) {
                    return x.charCodeAt(0);
                });
                var binData = new Uint8Array(charData);
                var data2 = pako.inflate(binData);
                var strData = String.fromCharCode.apply(
                    null,
                    new Uint16Array(data2)
                );

                var newObject = safeJsonParse(strData);



                setIncomingCall(true);
                setIncomingCallInfo(newObject);
                setDataCall(newObject);
            });

            channel.bind("finish_call", function (data) {
                var b64Data = data.payload;
                var strData = atob(b64Data);
                var charData = strData.split("").map(function (x) {
                    return x.charCodeAt(0);
                });
                var binData = new Uint8Array(charData);
                var data2 = pako.inflate(binData);
                var strData = String.fromCharCode.apply(
                    null,
                    new Uint16Array(data2)
                );

                var newObject = safeJsonParse(strData);


                setIncomingCall(false); // Set incoming call state to true when there's an incoming call event
                setFinsishCallInfo(newObject);
                setDataCall(newObject);
            });

            let pendingAcks = [];
            channel.bind("sync", function (data) {
                var b64Data = data.payload;
                var strData = atob(b64Data);
                var charData = strData.split("").map(function (x) {
                    return x.charCodeAt(0);
                });
                var binData = new Uint8Array(charData);
                var data = pako.inflate(binData);
                var strData = String.fromCharCode.apply(
                    null,
                    new Uint16Array(data)
                );
                var newObject = safeJsonParse(strData);
                console.log("newObject", newObject);

                if (newObject?.kafkaMsgId) {
                    // pendingAcks.push({
                    //     kafkaMsgId: newObject.kafkaMsgId,
                    //     deviceId: deviceId,
                    //     timestamp: new Date().toISOString()
                    // });

                    // Send batched acks every 100ms
                    // debouncedTrigger(channel, "client-acknowledgment-receipt", pendingAcks);
                    pendingAcks = [];
                    channel.trigger("client-acknowledgment-receipt", {
                        kafkaMsgId: newObject.kafkaMsgId,
                        deviceId: deviceId, 
                        timestamp: new Date().toISOString()
                    });
                }

            
                if (newObject?.instanceOf === 302) {
                    channel.trigger("client-delivery-receipt", {
                        userId: UserLocID,
                        chatId: newObject?.data?.chat_id,
                        messageId: newObject?.data?.id,
                        deviceId: deviceId,
                        timestamp: new Date().toISOString()
                    });

                    if (
                        newObject?.data?.chat_id.toString() ===
                        localStorage.getItem("roomId")
                    ) {                        
                        channel.trigger("client-read-receipt", {
                            userId: UserLocID,
                            chatId: newObject?.data?.chat_id,
                            messageId: newObject?.data?.id,
                            deviceId: deviceId,
                            timestamp: new Date().toISOString()
                        });
                        var wasTriggered = channel.trigger("client-sync", {
                            hash: [newObject?.hash.toString()],
                            seen: true,
                        });
                        // SetNewMessage({ ...newObject?.data, outFetch: true });
                    } else {
                        var wasTriggered = channel.trigger("client-sync", {
                            hash: [newObject?.hash.toString()],
                        });
                    }
                    if (
                        newObject?.action === 111 &&
                        newObject?.data?.chat_id ==
                        parseInt(localStorage.getItem("roomId"))
                    ) {                        
                        SetNewMessage({...newObject?.data,outFetch:false,
                            });
                    } else if (newObject?.action === 111) {
                        SetNewMessage({ ...newObject?.data, outFetch: true });
                    }
                    if (
                        newObject?.data?.text &&
                        newObject?.data?.is_mine === 0 &&
                        newObject?.data?.chat_id !==
                        parseInt(localStorage.getItem("roomId"))
                    ) {
                        console.log(
                            "checkNotification",
                            allMessagesId?.includes(newObject?.data?.id),
                            allMessagesId,
                            newObject?.data?.id
                        );
                        if (
                            allMessagesId.length !== 0 ||
                            !allMessagesId?.includes(newObject?.data?.id)
                        ) {
                            // notification.config({ maxCount: 2 });
                            // notification.destroy();
                            let newIds = allMessagesId;
                            newIds.push(newObject?.data?.id);
                            setAllMessages([...newIds]);
                            notification.open({
                                onClick: () => {
                                    if (showOldLog) {
                                        console.log("RoomRoomchangedappp", newObject?.data);
                                    }
                                    if (newObject?.data?.chat_type === 1) navigate("/messages");
                                    else navigate("/spaces");
                                    SetNotificationRoom(newObject?.data);
                                },
                                className: "messageNotification",
                                message:
                                    newObject?.data?.chat_type === 1
                                        ? newObject?.data?.user?.full_name
                                        : newObject?.data?.chat_name +
                                        " - " +
                                        newObject?.data?.user?.full_name,
                                description: newObject?.data?.text,
                                icon: (
                                    <img
                                        style={{
                                            width: 50,
                                            height: 50,
                                            objectFit: "cover",
                                            borderRadius: "50%",
                                            marginInlineEnd: 10,
                                        }}
                                        src={newObject?.data?.user?.profile_image.url}
                                    />
                                ),
                            });
                        }
                    }
                }
                if (newObject?.instanceOf === 304) {
                    console.log("instanceOf 304");
                    SetNewMessage({ refetch: true });
                }
                if (newObject?.instanceOf === 305) {
                    console.log("instanceOf 305");
                    SetNewMessage({ refetch: true });
                }

                if (newObject?.instanceOf === 303) {
                    console.log("instanceOf 303");
                    if (newObject?.data?.type == 2) {
                        setIncomingCall(true);
                        setIncomingCallInfo(newObject);
                        setDataCall(newObject);
                    } else if (newObject?.data?.type == 3) {
                        setIncomingCall(false);
                        setFinsishCallInfo(newObject);
                        setDataCall(newObject);
                    }
                }

                if (newObject?.instanceOf === 302 && newObject?.action === 444) {
                    SetNewMessage({
                        deletedMessageId: newObject?.data?.id,
                        action: 'delete',
                        refetch: true,
                    });
                }

                if (newObject?.instanceOf === 302 && newObject?.action === 222) {
                    if (newObject?.data?.chat_id !==
                        parseInt(localStorage.getItem("roomId"))) {                            
                        SetNewMessage({
                            updatedMessageId: newObject?.data?.id,
                            action: 'update',
                            refetch: false,
                            ...newObject?.data,
                        });
                    } else {
                        SetNewMessage({
                            updatedMessageId: newObject?.data?.id,
                            action: 'update',
                            refetch: false,
                            data:newObject?.data,
                            ...newObject?.data,
                        });
                    }

                }

                if (newObject?.instanceOf === 306 && newObject?.action === 222 && newObject?.data?.chat_id !==
                    parseInt(localStorage.getItem("roomId")) && newObject?.data?.readAt) {
                    if (newObject?.data?.chat_id !==
                        parseInt(localStorage.getItem("roomId"))) {                            
                        SetNewMessage({
                            updatedMessageId: newObject?.data?.messageId,
                            action: 'updateSeen',
                            refetch: false,
                        });
                    } 

                }

            });

            channel.bind("client-sync", function (data) {
                var b64Data = data.payload;
                var strData = safeAtob(b64Data);
                var charData = strData.split("").map(function (x) {
                    return x.charCodeAt(0);
                });
                var binData = new Uint8Array(charData);
                var data = pako.inflate(binData);
                var strData = String.fromCharCode.apply(
                    null,
                    new Uint16Array(data)
                );
                var newObject = safeJsonParse(strData);

            });
            // Cleanup function
            return () => {
                if (channelName) {
                    pusherRef.current?.unsubscribe(channelName);
                    if (socket) {
                        disconnectSocket(socket);
                    }
                }
            };
        }
    }, [UserLocID, accessToken]);

    Pusher.log = (msg) => {
        console.log("data log", msg);
    };





    return (
        <></>
    )
}

export default PusherConnection