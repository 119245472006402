import React, { useEffect, useState } from 'react'
import { Modal, List, Button, Tag, Space, message, Tabs, Skeleton } from 'antd'
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons'
import ChatActions from '../../../../actions/ChatActions'
import dayjs from 'dayjs'
import { REACT_APP_SOCKET_BASE_URL } from '../../../../ConfigVar'
import { saveAs } from 'file-saver';

const ExportedChatModal = ({ chatInfo }) => {
  const [visible, setVisible] = useState(false)


  const onCancel = () => {
    setLoading(false)
    setVisible(false)
  }

  const [loading, setLoading] = useState(false)
  const [exportedList, setExportedList] = useState([])
  const [chatId, setChatId] = useState(chatInfo?.id)

  const getExportedList = async () => {
    setLoading(true)
    let params = {
      chat_id: chatId
    }
    let result = await ChatActions.getExportedChatRequest(params)
    if (result?.json?.length > 0) {
      setExportedList(result?.json)
    } else {
      message.error(result?.message)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (visible && chatId) {
      getExportedList()
    }

  }, [visible,chatId])

  const handleDownload = async (downloadUrl) => {
    window.open(REACT_APP_SOCKET_BASE_URL + "/" + downloadUrl, '_blank')

    // if (downloadUrl.endsWith('.txt')) {
    //   try {
    //     const response = await fetch(REACT_APP_SOCKET_BASE_URL + "/" + downloadUrl)
    //     const blob = await response.blob()
    //     saveAs(blob, `exported-chat-${dayjs().format('YYYY-MM-DD')}.txt`)
    //   } catch (error) {
    //     message.error('Failed to download file')
    //   }
    // } else {
    //   window.open(REACT_APP_SOCKET_BASE_URL + "/" + downloadUrl, '_blank')
    // }
  }
  const exportType = [
    { label: 'Text', value: 1 },
    { label: 'Voice', value: 2 },
    { label: 'Audios', value: 3 },
    { label: 'Images', value: 4 },
    { label: 'Videos', value: 5 },
    { label: 'Document', value: 6 },
  ];


  useEffect(() => {
    setChatId(chatInfo?.id)
  }, [chatInfo?.id])

  return (
    <>
      <Button block type="primary" onClick={() => { setVisible(true) }}>
        Exported List
      </Button>

      <Modal
        title="Exported Chat Requests"
        open={visible}
        onCancel={onCancel}
        footer={null}
        width={600}
      >
        {chatInfo?.staff_chat?.id && <Tabs
        onChange={(e)=>{setChatId(e)}}
          defaultActiveKey={chatInfo?.id}
          items={[
            { label: "Internal", key: chatInfo?.id },
            { label: "External", key: chatInfo?.staff_chat?.id }
          ]}
        />}
        {loading ? (
          <List
            itemLayout="horizontal"
            dataSource={[1, 2, 3, 4, 5]}
            renderItem={() => (
              <List.Item
              extra={<Skeleton.Button active />}
              >
                <Skeleton active avatar={false} paragraph={{ rows: 1 }} />
              </List.Item>
            )}
          />
        ) : (
          <List
            itemLayout="horizontal"
            dataSource={exportedList}
            renderItem={(item) => (
              <List.Item
                extra={
                  item.status === 2 ? (
                    <Button
                      type="primary"
                      icon={<DownloadOutlined />}
                      onClick={() => handleDownload(item.file)}
                    >
                      Open
                    </Button>
                  ) : null
                }
              >
                <List.Item.Meta
                  title={`Export Request #${item.id} (${exportType?.find(e => e.value == item?.type)?.label})`}
                  description={
                    <Space>
                      <span>Requested on: {dayjs(item.created_at).format("DD/MM/YYYY hh:mm")}</span>
                      <Tag color={item.status === 2 ? 'green' : 'gold'}>
                        {item.status === 2 ? (
                          'Ready'
                        ) : (
                          <><LoadingOutlined /> Waiting</>
                        )}
                      </Tag>
                    </Space>
                  }
                />
              </List.Item>
            )}
          />
        )}
      </Modal></>

  )
}

export default ExportedChatModal