import { notification } from "antd";
import axios from "axios";
import { DeviceUUID } from "device-uuid";
import ApiStorage from "../redux/ApiStorage";
import { REACT_APP_API_BASE_URL } from "../ConfigVar";

// import { DeviceUUID } from "device-uuid";

const API_URL = process.env.REACT_APP_API_URL;

const login = (username: any, password: string ,device_id: string) => {
  var uuid = new DeviceUUID().get();
  let config = {
    headers: { "device-id": device_id },
  };
  return axios
    .post(
      REACT_APP_API_BASE_URL + "auth/login",
      {
        username,
        password,
        device:device_id
      },
      { ...config }
    )
    .then((response:any) => {
      if (response.data.json.token) {
        ApiStorage.setAccessToken(response.data.json.token);
        localStorage.setItem("user", JSON.stringify(response.data.json));
      }
      return response.data;
    });
};
const logout = async() => {
  let config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };

  return axios
    .post(
      REACT_APP_API_BASE_URL + "auth/logout",
      { _method: "post" ,
        device: await ApiStorage.getDeviceId()
      },
      { ...config }
    )
    .then((response) => {
      localStorage.removeItem("token");
      localStorage.removeItem("user");

      return response;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        return true;
      } else {
        notification.open({
          type: "error",
          message: error.message,
          duration: 300,
        });
      }
    });
};

export default {
  login,
  logout,
};
