import { Button, Modal, Form, Select, Checkbox, Space, Radio, Row, Col, message } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import React, { useState } from 'react'
import ChatActions from '../../../../actions/ChatActions';

const ExportModal = (props) => {
  const { chatInfo } = props;
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const showExportModal = () => {
    setIsExportModalVisible(true);
  };

  const handleExportCancel = () => {
    setIsExportModalVisible(false);
    form.resetFields();
  };



  const contentOptions = [
    { label: 'Text', value: 1 },
    { label: 'Voice', value: 2 },
    { label: 'Audios', value: 3 },
    { label: 'Images', value: 4 },
    { label: 'Videos', value: 5 },
    { label: 'Document', value: 6 },
  ];

  // Add this styles object at the bottom of the file
  const styles = {
    checkboxRow: {
      padding: '8px 12px',
      borderRadius: '4px',
      transition: 'all 0.3s ease',
      width: '100%',
      cursor: 'pointer',
    },
    selectedRow: {
      backgroundColor: 'rgba(155, 23, 36, 0.32)', // Light blue background for selected
      border: '1px solid #9b1724',
    },
    unselectedRow: {
      backgroundColor: '#ffffff',
      border: '1px solid #d9d9d9',
    }
  };

  const enumValue = useWatch("enum", form)

  const onFinish = async (values) => {
    setLoading(true)
    values.id = values?.chat_id ? values?.chat_id :  chatInfo?.id
    let result = await ChatActions.exportChat(values)
    if(result?.json?.id){
      message.success("Exported Request one Success , You Can Find the Request in Requested List ")
    }
    setLoading(false)
  }


  return (
    <>
      <Button block onClick={showExportModal}>
        Export Chat
      </Button>

      <Modal
        title="Export Chat"
        open={isExportModalVisible}
        onOk={() => { form.submit() }}
        onCancel={handleExportCancel}
        destroyOnClose={true}
        okText={"Export"}
        okButtonProps={{
          loading: loading,
          
        }}

      >
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          initialValues={{
            type: 1,
            chat_id:chatInfo?.id
          }}
        >
          {chatInfo?.staff_chat?.id &&
            <Form.Item
              name="chat_id"
              label="Chat Type"
              rules={[{ required: true, message: 'Please select chat type' }]}
            >
              <Radio.Group defaultValue={chatInfo?.id} >
                <Space direction="horizontal">
                  <Radio value={chatInfo?.id}  >
                    {"Internal"}
                  </Radio>
                  <Radio value={chatInfo?.staff_chat?.id}  >
                    {"External"}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>}
          <Form.Item
            name="type"
            label="Content to Export"
            rules={[{ required: true, message: 'Please select at least one content type' }]}
          >
            <Radio.Group>
              <Row gutter={[5, 5]} style={{ width: '100%' }}>
                {contentOptions.map(option => (
                  <Col span={24}
                    key={option.value}
                    style={{
                      ...styles.checkboxRow,
                      ...(enumValue?.includes(option.value)
                        ? styles.selectedRow
                        : styles.unselectedRow)
                    }}
                  >
                    <Radio value={option.value}>
                      {option.label}
                    </Radio>
                  </Col>
                ))}
              </Row>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default ExportModal