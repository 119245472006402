import io from 'socket.io-client';
import { REACT_APP_SOCKET_BASE_URL } from '../ConfigVar';
import ApiStorage from '../redux/ApiStorage';

export const connectSocket = async () => {
  const user = JSON.parse(window.localStorage.getItem("user"));
  const deviceId = await ApiStorage.getDeviceId();

  const socket = io(REACT_APP_SOCKET_BASE_URL, {
    // transports: ['websocket',"polling"],
    path: '/socket.io/',
    extraHeaders: {
      // 'Connection': 'Upgrade',
      // 'Upgrade': 'websocket',
      // 'Sec-WebSocket-Extensions': 'permessage-deflate;client_max_window_bits',
      'userId': user?.id?.toString(),
      'deviceId': deviceId
    },
    reconnectionAttempts: 5,
    reconnectionDelay: 3000,
    timeout: 10000,
    autoConnect: true,
    forceNew: true
  });

  // Enhanced connection monitoring
  socket.io.on("ping", () => {
    console.log("Connection alive - ping received");
    socket.emit("presenceEvent", {
      userId: user?.id,
      deviceId: deviceId,
      status: "online",
      timestamp: new Date().toISOString()
    });
  });

  socket.io.on("open", () => {
    console.log("WebSocket connection established");
  });

  socket.on("disconnect", (reason) => {
    console.log("Socket disconnected:", {
      reason,
      socketId: socket.id,
      wasConnected: socket.connected,
      timestamp: new Date().toISOString()
    });
  });

  socket.io.on("error", (error) => {
    if (error && error.type) {
      console.log("Socket error detected:", {
        errorType: error.type,
        errorMessage: error.message,
        socketId: socket.id,
        timestamp: new Date().toISOString()
      });
    }
  });


  return socket;
}


export const disconnectSocket = (socket) => {
  if (socket) {
    console.log("disconnectSocket");
    socket.disconnect();
    return true;
  }
  return false;
};
