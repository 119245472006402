import React, { useEffect, useState } from "react";

import spacesStyles from "../../../styles/pages/spaces.module.scss";
import RoomChat from "../../room/roomChat";
import { Button, Col, Input, Modal, Row, Tabs } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import { QueryClient, useInfiniteQuery, useQueryClient } from "react-query";
import EditGroupInfo from "../editGroup";
import GroupContentInfo from "../contentInfo/groupInfo";
import UserContentInfo from "../contentInfo/userInfo";
import { FetchMessages } from "../../../services/chats";

import { database } from "../../../firebaseConfig";
import { ref, set, get, onValue } from "firebase/database";
import moment from "moment";
import { showOldLog } from "../../../ConfigVar";

const { Search } = Input;

const Messages = ({
  SubRoomId,
  user,
  setRoomId,
  setSubRoomId,
  setUser,
  group,
  RoomId,
  contact,
  onFinish,
  IsExternal,
  refetchGroups,
  activeKey,
  setActiveKey,
}: any) => {
  const [UserLoc, setUserLoc] = useState<any>({});
  const [isInfoOpen, setIsInfoOpen] = useState<boolean>(false);
  const [SearchMessage, setSearchMessage] = useState<string>("");
  const [openSearchModal, setOpenSearchModal] = useState<boolean>(false);
  const [LastSeen, setLastSeen] = useState<any>({});
  useEffect(() => {
    if (user && user?.members && user?.members[0]?.id && !group) {
      const dbRef = ref(database, user?.members[0]?.id.toString());
      onValue(dbRef, (snapshot) => {
        setLastSeen(snapshot.val());
      });
      // get(dbRef)
      //   .then((snapshot) => {
      //     if (snapshot.exists()) {
      //       console.log("databaseDate", snapshot.val());
      //       setLastSeen(snapshot.val());
      //     } else {
      //       console.log("databaseDateNo data available");
      //     }
      //   })
      //   .catch((error) => {
      //     console.error("databaseDate", error);
      //   });
    }
  }, [user]);

  useEffect(() => {
    if (showOldLog) {
      console.log("RoomRoomchangedRoomIdmiddle", RoomId);
    }
    setIsInfoOpen(RoomId.toString().includes("open"));
  }, [RoomId]);
  useEffect(() => {
    const t = JSON.parse(window.localStorage.getItem("user") as string);
    setUserLoc(t);
  }, [window.localStorage.getItem("user")]);

  const queryClient = useQueryClient();

  const toggleIsInfoOpen = () => {
    setIsInfoOpen(!isInfoOpen);
  };
  const closeIsInfoOpen = () => {
    setIsInfoOpen(false);
  };

  const openEditInfo = (info: any) => {
    const createModal = Modal.info({
      className: "addspaceModal",
      autoFocusButton: null,
      title: (
        <div
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "space-between",
          }}
        >
          {"Edit group info"}
        </div>
      ),
      width: "40%",
      centered: true,
      content: (
        <EditGroupInfo Group={info} setGroup={setUser} onFinish={onFinish} />
      ),
      icon: <></>,
      footer: [],
      afterClose() {
        queryClient.invalidateQueries("groups");
        queryClient.invalidateQueries("groupsData");
      },
      closeIcon: (
        <>
          <CloseOutlined color="#fff" />
        </>
      ),
      maskClosable: true,
      closable: true,
    });
  };

  const searchMessage = useInfiniteQuery(
    ["MessagesSearch", SearchMessage, RoomId],
    async ({ pageParam = 1 }) =>
      FetchMessages(RoomId, pageParam, 0, SearchMessage),
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage) => false,
      cacheTime: 0,
      staleTime: Infinity,
      refetchInterval: 10000,
      enabled:
        RoomId !== undefined &&
        RoomId !== 0 &&
        SearchMessage !== "" &&
        RoomId !== null &&
        !isNaN(RoomId),
    }
  );

  return (
    <>
      <div className={spacesStyles.messages}>
        {user?.name || user?.full_name || user?.contact?.full_name ? (
          <Row>
            <Col span={isInfoOpen ? 14 : 24}>
              <div id="chatHeader" className={spacesStyles.chatHeader}>
                <div>
                  <button
                    onClick={() => {
                      localStorage.setItem("roomId", "0");
                      setRoomId(0);
                      setSubRoomId(0);
                      setUser({});
                      closeIsInfoOpen();
                    }}
                    className={spacesStyles.backBtn}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4 12H20"
                        stroke="#0D0F11"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8.99996 17C8.99996 17 4.00001 13.3176 4 12C3.99999 10.6824 9 7 9 7"
                        stroke="#0D0F11"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                  <div className={spacesStyles.groupInfo}>
                    <img
                      src={
                        contact
                          ? user?.contact?.profile_image?.url
                          : user?.icon?.url
                            ? user?.icon?.url
                            : user?.profile_image?.url
                      }
                      alt=""
                    />
                    <div>
                      <h3>
                        {contact
                          ? user?.contact?.full_name
                          : user?.name
                            ? user?.name
                            : user?.full_name}
                      </h3>
                      <p>
                        {Object.keys(LastSeen).length > 0 ? (
                          LastSeen?.presence ? (
                            <span>Online</span>
                          ) : (
                            moment(LastSeen?.last_seen)
                              .local()
                              .format("DD MMM YYYY [AT] hh:mm A")
                              .toString()
                          )
                        ) : (
                          ""
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className={spacesStyles.groupBtns}>
                  <button onClick={() => setOpenSearchModal(true)}>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.5 17.5L22 22"
                        stroke="#0D0F11"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M20 11C20 6.02944 15.9706 2 11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20C15.9706 20 20 15.9706 20 11Z"
                        stroke="#0D0F11"
                        stroke-width="1.5"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                  <Modal
                    className={"addspaceModal"}
                    title={"Search in messages"}
                    open={openSearchModal}
                    afterClose={() => setSearchMessage("")}
                    onCancel={() => setOpenSearchModal(false)}
                    width={"40%"}
                    footer={[]}
                    centered
                    destroyOnClose
                  >
                    <div style={{ minHeight: "70vh" }}>
                      <Search
                        placeholder={
                          "Seach for message in " + user.name + " group"
                        }
                        onSearch={(e: any) => setSearchMessage(e)}
                        onChange={(e: any) => {
                          if (e.target.value === "") setSearchMessage("");
                        }}
                      />
                      <div className="searchMessageCon">
                        {searchMessage?.data?.pages?.[0]?.json?.length ===
                          0 && <div className="notFound">No Result found</div>}
                        {!searchMessage.isFetching &&
                          SearchMessage !== "" &&
                          searchMessage?.data?.pages?.[0]?.json?.map(
                            (searchMsg: any) => (
                              <div className="searchMessage">
                                <div className="searchUser">
                                  <img
                                    src={searchMsg.user?.profile_image?.url}
                                    alt=""
                                  />
                                </div>
                                <div className="searchMessageText">
                                  <h3>{searchMsg?.user?.full_name}</h3>
                                  <p>
                                    {searchMsg?.text}
                                    <div className="messageDate">
                                      {searchMsg?.created_at.split("T")[0]}
                                    </div>
                                  </p>
                                </div>
                              </div>
                            )
                          )}
                      </div>
                    </div>
                  </Modal>
                  {!contact && (
                    <button onClick={() => toggleIsInfoOpen()}>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12Z"
                          stroke="#0D0F11"
                          stroke-width="1.5"
                        />
                        <path
                          d="M12.2422 17V12C12.2422 11.5286 12.2422 11.2929 12.0957 11.1464C11.9493 11 11.7136 11 11.2422 11"
                          stroke="#0D0F11"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M11.9922 8H12.0012"
                          stroke="#0D0F11"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                  )}
                </div>
              </div>
              {SubRoomId !== 0 && UserLoc?.role !== "client" && IsExternal ? (
                <Tabs
                  onChange={(activeKey: string) => setActiveKey(activeKey)}
                  activeKey={activeKey}
                  items={[
                    {
                      key: "1",
                      label: "Internal",
                      children: (
                        <RoomChat
                          group={group}
                          user={user}
                          roomId={SubRoomId}
                          setRoomId={(Id: any) => setRoomId(Id)}
                        />
                      ),
                    },
                    {
                      key: "2",
                      label: "External",
                      children: (
                        <RoomChat
                          group={group}
                          user={user}
                          roomId={parseInt(RoomId)}
                          setRoomId={(Id: any) => setRoomId(Id)}
                        />
                      ),
                    },
                  ]}
                />
              ) : (
                <>
                  {/* {RoomId}-{SubRoomId} */}
                  {UserLoc?.role === "client" || SubRoomId === 0 ? (
                    <RoomChat
                      group={group}
                      user={user}
                      roomId={parseInt(RoomId)}
                      setRoomId={(Id: any) => setRoomId(Id)}
                      contact={contact}
                      newMessageRefetch={() => onFinish()}
                    />
                  ) : (
                    <RoomChat
                      group={group}
                      user={user}
                      roomId={parseInt(SubRoomId)}
                      setRoomId={(Id: any) => setRoomId(Id)}
                      contact={contact}
                      newMessageRefetch={() => onFinish()}
                    />
                  )}
                </>
              )}
            </Col>
            <Col span={isInfoOpen ? 10 : 0}>
              <div id="chatHeader" className={spacesStyles.chatHeader}>
                <div>
                  <button
                    onClick={() => closeIsInfoOpen()}
                    className={spacesStyles.backBtn}
                  >
                    <CloseOutlined />
                  </button>
                  <div
                    style={{
                      height: "40px",
                      fontSize: "18px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {group ? "Group info" : "User info"}
                  </div>
                </div>
                {group &&
                  (UserLoc?.role === "owner" ||
                    UserLoc?.role === "su" ||
                    UserLoc?.role === "admin") &&
                  user?.is_admin === 1 && (
                    <div className={spacesStyles.editInfo}>
                      <Button
                        type="text"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          color: "#9b1724",
                        }}
                        onClick={() => openEditInfo(user)}
                      >
                        EDIT INFO <img src="/images/edit-red.svg" alt="" />
                      </Button>
                    </div>
                  )}
              </div>
              {group ? (
                <GroupContentInfo
                  user={user}
                  roomId={parseInt(RoomId)}
                  refetchGroups={() => refetchGroups()}
                />
              ) : (
                !contact && (
                  <UserContentInfo user={user} roomId={parseInt(RoomId)} />
                )
              )}
            </Col>
          </Row>
        ) : (
          <div className={spacesStyles.emptyMessages}>
            <svg
              width="89"
              height="88"
              viewBox="0 0 89 88"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="1"
                y="0.5"
                width="87"
                height="87"
                rx="23.5"
                stroke="#C7C7CC"
              />
              <path
                d="M61.1667 43.278C61.1667 52.0833 53.7037 59.2225 44.5 59.2225C43.4179 59.224 42.3387 59.1238 41.2757 58.9243C40.5106 58.7805 40.128 58.7087 39.8609 58.7495C39.5938 58.7903 39.2153 58.9915 38.4584 59.3942C36.3169 60.533 33.82 60.9352 31.4186 60.4885C32.3313 59.3658 32.9546 58.0188 33.2297 56.5748C33.3963 55.6915 32.9834 54.8335 32.3649 54.2053C29.5556 51.3527 27.8334 47.5087 27.8334 43.278C27.8334 34.4728 35.2963 27.3335 44.5 27.3335C53.7037 27.3335 61.1667 34.4728 61.1667 43.278Z"
                fill="#E5E5EA"
                stroke="#0D0F11"
                stroke-width="1.875"
                stroke-linejoin="round"
              />
              <path
                d="M41.1666 40.4104C41.1666 38.7111 42.659 37.3335 44.5 37.3335C46.341 37.3335 47.8333 38.7111 47.8333 40.4104C47.8333 41.023 47.6395 41.5937 47.3051 42.0732C46.309 43.5022 44.5 44.865 44.5 46.5643V47.3335"
                stroke="#0D0F11"
                stroke-width="1.875"
                stroke-linecap="round"
              />
              <path
                d="M44.5 51.5H44.515"
                stroke="#0D0F11"
                stroke-width="1.875"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <h3>No chat selected</h3>
            <p>Choose from the menu to view.</p>
          </div>
        )}
      </div>
    </>
  );
};

export default Messages;
